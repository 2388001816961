.footer-container{
  justify-content: space-around !important; /* make icons centered*/

}

@media only screen and (min-width: 768px) {
  .navbar-footer{
    display: none;
  }
}
.footerIcon{
  color: white;
  font-size: 20px;
}
.footer-mobile{
  box-shadow: 0px -2px 6px 4px rgba(0,0,0,0.3);
}