$theme-colors: (
        "primary": #20a75f
);

$primary: #20a75f;

@import '../../node_modules/bootstrap/scss/bootstrap';
@import 'header';
@import 'homepage';

