.footer{
    bottom: 0;
    width: 100%;
    /* Set the fixed height of the footer here */
    clear: both;
    position: absolute;
    height: 60px;
    margin-top: -60px;
    background-color: #f5f5f5;
    z-index: -1;
  }