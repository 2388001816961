
.product-item{
  align-items: center;
}
.caroussel-product{
  display: flex;
}
.card-product{
  max-width: 420px;
  min-width: 350px;
  padding-left: 70px;
  padding-right: 70px;
  padding-top: 50px;
}
.product-col{
  width: 540px !important;
}

