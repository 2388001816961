.pp-button-choose {
  background-color: #6610f2 !important;
}
.bd-placeholder-img {
  font-size: 1.125rem;
  text-anchor: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.container-homepage{
  margin-top: 20px;
}

@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}
@media (max-width: 992px) {
  .item-hidden-992{
    display: none;
  }
}
.adminIcon{
  font-color: black;
}


.container-form-jumbotron{
  margin-top:130px;
  position: relative;
  display: block;
  z-index: 101;
}
.img-jumbotron video{
  width: 100%;
}


.jumbotron-label{
  z-index: 101;
  line-height: 1.3;
  font-weight: 700;
  margin-bottom: 4px;
  overflow: hidden;
  -webkit-animation: fadein 1s;
  animation: fadein 1s;
  text-transform: uppercase;
  font-size: 24px !important;
  color: black !important;
  margin-block-start: 1em;
  margin-block-end: 1em;
  color: white !important;
}
.div-jumbotron-form{
  background-color: rgb(248, 247, 249);
  border-radius: 16px;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 8px 3px;
  opacity: 0.95;
}
.div-cities{
  position: relative;
  display: block;
  z-index: 101;

  background-color: rgb(248, 247, 249);
  border-radius: 16px;
  box-shadow:  rgba(0, 0, 0, 0.2) 0px 2px 8px 3px;
  margin-top: 50px;

}

.div-circles{
  justify-content: center
}
.div-circles >div{
  margin: 10px;

}
.button-reservation{
  margin-top: 5px;
}

.pp-button-home-level{
  margin-top: 10px !important;
}
.image_logo_home{
  opacity: 0.8;
}

.modal-loader{
  background-color: transparent !important;
  border: none !important;
}





