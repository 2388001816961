.pp-navbar-custom {
  border-bottom-color: #A8DBED;
  border-bottom-style: solid;
}

.pp-navbar-logo {
  height: 60px;
}

.pp-link-underscore-primary {
  position: relative;
  display: inline-block;
  padding-bottom: 2px; // Ajustez selon vos préférences

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px; // Épaisseur du soulignement
    background-color: $primary;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  &:hover:after,
  &.active:after { // Style pour le lien actif
    opacity: 1;
  }
}